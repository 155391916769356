
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const Footer = ({ locale }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "footer_md"}}}) {
          nodes {
            frontmatter {
                type
                follow_he
                areas_he
                area1_he
                area2_he
                area3_he
                area4_he
                area5_he
                area6_he
                area7_he
                area8_he
                area9_he
                products_he
                product1_he
                product2_he
                product3_he
                product4_he
                product6_he
                product7_he
                product9_he
                product10_he
                product11_he
                product12_he
                about_he
                contact_he
                catalogue_he
                our_work_he
                follow_ru
                areas_ru
                area1_ru
                area2_ru
                area3_ru
                area4_ru
                area5_ru
                area6_ru
                area7_ru
                area8_ru
                area9_ru
                products_ru
                product1_ru
                product2_ru
                product3_ru
                product4_ru
                product6_ru
                product7_ru
                product9_ru
                product10_ru
                product11_ru
                product12_ru
                about_ru
                contact_ru
                catalogue_ru
                our_work_ru
                follow_en
                areas_en
                area1_en
                area2_en
                area3_en
                area4_en
                area5_en
                area6_en
                area7_en
                area8_en
                area9_en
                products_en
                product1_en
                product2_en
                product3_en
                product4_en
                product6_en
                product7_en
                product9_en
                product10_en
                product11_en
                product12_en
                about_en
                contact_en
                catalogue_en
                our_work_en
                follow_es
                areas_es
                area1_es
                area2_es
                area3_es
                area4_es
                area5_es
                area6_es
                area7_es
                area8_es
                area9_es
                products_es
                product1_es
                product2_es
                product3_es
                product4_es
                product6_es
                product7_es
                product9_es
                product10_es
                product11_es
                product12_es
                about_es
                contact_es
                catalogue_es
                our_work_es
                follow_fr
                areas_fr
                area1_fr
                area2_fr
                area3_fr
                area4_fr
                area5_fr
                area6_fr
                area7_fr
                area8_fr
                area9_fr
                products_fr
                product1_fr
                product2_fr
                product3_fr
                product4_fr
                product6_fr
                product7_fr
                product9_fr
                product10_fr
                product11_fr
                product12_fr
                about_fr
                contact_fr
                catalogue_fr
                our_work_fr
                follow_pr
                areas_pr
                area1_pr
                area2_pr
                area3_pr
                area4_pr
                area5_pr
                area6_pr
                area7_pr
                area8_pr
                area9_pr
                products_pr
                product1_pr
                product2_pr
                product3_pr
                product4_pr
                product6_pr
                product7_pr
                product9_pr
                product10_pr
                product11_pr
                product12_pr
                about_pr
                contact_pr
                catalogue_pr
                our_work_pr
                brand
                home
                follow_link_facebook
                follow_link_twitter
                follow_link_instagram
                
                follow_link_whatsapp
                areas_link
                area1_link
                area2_link
                area3_link
                area4_link
                area5_link
                area6_link
                area7_link
                area8_link
                area9_link
                product1_link
                product2_link
                product3_link
                product4_link
                product6_link
                product7_link
                product9_link
                product10_link
                product11_link
                product12_link
                about_link
                contact_link
                catalogue_link
                our_work_link            
            }
          }
        }
      }
    `}

    render={
        data => 
        <>







            <footer className="footer">
                <hr />
                <div className="container">
                <div className="row">

                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <h4 className="footerTitle">{data.allMarkdownRemark.nodes[0].frontmatter[`follow_${locale}`]}</h4>
                            <ul className="footerUL">

                            <li className="footerLink social" key="footer-item-26">
                                <a
                                    href={`${data.allMarkdownRemark.nodes[0].frontmatter[`follow_link_whatsapp`]}`}
                                    className="nav-link footer-link"
                                ><i className="fab fa-whatsapp fa-2x"></i>
                                </a>
                            </li>
                            <li className="footerLink social" key="footer-item-27">
                                <a
                                    href={`${data.allMarkdownRemark.nodes[0].frontmatter[`follow_link_instagram`]}`}
                                    className="nav-link footer-link"
                                ><i className="fab fa-instagram-square fa-2x"></i>
                                </a>
                            </li>
                            <li className="footerLink social" key="footer-item-28">
                                <a
                                    href={`${data.allMarkdownRemark.nodes[0].frontmatter[`follow_link_twitter`]}`}
                                    className="nav-link footer-link"
                                ><i className="fab fa-twitter fa-2x"></i>
                                </a>
                            </li>
                            <li className="footerLink social" key="footer-item-29">
                                <a
                                    href={`${data.allMarkdownRemark.nodes[0].frontmatter[`follow_link_facebook`]}`}
                                    className="nav-link footer-link"
                                ><i className="fab fa-facebook fa-2x"></i>

                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <h4 className="footerTitle">{data.allMarkdownRemark.nodes[0].frontmatter[`products_${locale}`]}</h4>
                        <ul className="footerUL">
                            <li className="footerLink" key="footer-item-01">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product1_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product1_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-02">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product2_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product2_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-03">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product3_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product3_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-04">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product4_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product4_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-05">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product6_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product6_${locale}`]}
                            </Link></li>
                            <li className="footerLink" key="footer-item-06">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product7_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product7_${locale}`]}
                            </Link></li>
                            <li className="footerLink" key="footer-item-07">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product9_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product9_${locale}`]}
                            </Link></li>
                            <li className="footerLink" key="footer-item-08">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product10_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product10_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-11">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product11_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product11_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-12">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`product12_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`product12_${locale}`]}
                                </Link>
                            </li>

                        </ul>
                        
                    </div>


                
                



                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <h4 className="footerTitle">{data.allMarkdownRemark.nodes[0].frontmatter[`areas_${locale}`]}</h4>
                        <ul className="footerUL">
                            <li className="footerLink" key="footer-item-13a">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area1_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area1_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-13">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area2_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area2_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-14">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area3_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area3_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-15">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area4_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area4_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-16">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area5_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area5_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-17">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area6_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area6_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-18">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area7_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area7_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-19">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area8_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area8_${locale}`]}
                                </Link>
                            </li>
                            <li className="footerLink" key="footer-item-20">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`area9_link`]}`}
                                    className="nav-link footer-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`area9_${locale}`]}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <h4 className="footerTitle">{data.allMarkdownRemark.nodes[0].frontmatter[`brand`]}</h4>
                        <ul className="footerUL">

                        <li className="footerLink" key="footer-item-21">
                            <Link
                                to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`about_link`]}`}
                                className="nav-link footer-link"
                            >{data.allMarkdownRemark.nodes[0].frontmatter[`about_${locale}`]}
                            </Link>
                        </li>


                        <li className="footerLink" key="footer-item-22">
                            <Link
                                to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`contact_link`]}`}
                                className="nav-link footer-link"
                            >{data.allMarkdownRemark.nodes[0].frontmatter[`contact_${locale}`]}
                            </Link>
                        </li>


                        <li className="footerLink" key="footer-item-23">
                            <Link
                                to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`catalogue_link`]}`}
                                className="nav-link footer-link"
                            >{data.allMarkdownRemark.nodes[0].frontmatter[`catalogue_${locale}`]}
                            </Link>
                        </li>


                        <li className="footerLink" key="footer-item-24">
                            <Link
                                to={`${locale === "he" ? "" : locale}/${data.allMarkdownRemark.nodes[0].frontmatter[`our_work_link`]}`}
                                className="nav-link footer-link"
                            >{data.allMarkdownRemark.nodes[0].frontmatter[`our_work_${locale}`]}
                            </Link>
                        </li>
                        </ul>
                    </div>

 


                </div>
                <div className="row">
                    <div className="col-12">
                        <span>ARTDOOR &copy; {new Date(Date.now()).getFullYear()}</span>
                    </div>
                </div>

                </div>



            </footer>






        </>
    }
  ></StaticQuery>


)

export default Footer


