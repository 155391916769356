import React from "react"

import Locale from '../components/locale'
import Navbar from '../components/navbar'
import Call from '../components/call'

const Header = ({locale, pagePath}) => {

    return (
        <React.Fragment>
            <div className="headerArtdoor">

                <Call />
                <hr />

                <Navbar 
                    locale={locale}
                    pagePath={pagePath}
                />
                <Locale 
                    currentLocale={locale}
                    pagePath={pagePath}
                />
                <hr />
            </div>

            
        </React.Fragment>
    )
}
export default Header