 import React from "react"

 const Call = () => {
 
     return (
         <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12 callUs">
                        <div className="callMobile">
                            <a href="tel:972545238251"><i className="fas fa-mobile-alt fa-3x"></i><span className="topPhoneNumber"> 0545-23-82-51</span></a>
                        </div>
                    </div>
                </div>
              </div>

 
         </React.Fragment>
     )
 }
 export default Call