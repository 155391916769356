import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'

const Navbar = ({ locale }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "navbar_md"}}}) {
          nodes {
            frontmatter {
              type
              home_en
              home_es
              home_he
              home_pr
              home_ru
              home_fr
              about_en
              about_es
              about_fr
              about_pr
              about_he
              catalogue_en
              about_ru
              catalogue_es
              catalogue_fr
              catalogue_he
              catalogue_ru
              contact_en
              catalogue_pr
              contact_fr
              contact_es
              contact_he
              contact_ru
              contact_pr
              entrance_doors_en
              entrance_doors_es
              entrance_doors_fr
              entrance_doors_he
              entrance_doors_pr
              entrance_doors_ru
              kitchens_ru
              kitchens_pr
              kitchens_he
              kitchens_fr
              kitchens_es
              kitchens_en
              our_work_en
              our_work_es
              our_work_fr
              our_work_he
              our_work_pr
              our_work_ru
              interios_doors_en
              interios_doors_fr
              interios_doors_ru
              interios_doors_pr
              interios_doors_he
              interios_doors_es
              sliding_doors_ru
              sliding_doors_pr
              sliding_doors_he
              sliding_doors_fr
              sliding_doors_en
              sliding_doors_es
              doors_ru
              doors_pr
              doors_es
              doors_en
              doors_fr
              doors_he
              img_logo {
                    childImageSharp {
                        fixed(width: 278) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
          }
        }
      }
    `}

    render={
        data => 
        <>

            <nav id="artdoor_navbar" className="navbar navbar-expand-lg navbar-light bg-light">

            <div className="container">

            <Link
                to={`${locale === "he" ? "" : locale}`}
                className="navbar-brand"
            >
            <Img fixed={data.allMarkdownRemark.nodes[0].frontmatter.img_logo.childImageSharp.fixed} />
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li key="nav-item-01" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`home_${locale}`]}</Link>
                    </li>

                    <li key="nav-item-02" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}/about`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`about_${locale}`]}</Link>
                    </li>
                    <li key="nav-item-03" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}/catalogue`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`catalogue_${locale}`]}</Link>
                    </li>
                    <li key="nav-item-04" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}/contact`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`contact_${locale}`]}</Link>
                    </li>
                    <li key="nav-item-05" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}/kitchen`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`kitchens_${locale}`]}</Link>
                    </li>
                    <li key="nav-item-06" className="nav-item">
                        <Link
                            to={`${locale === "he" ? "" : locale}/our-work`}
                            className="nav-link"
                        >{data.allMarkdownRemark.nodes[0].frontmatter[`our_work_${locale}`]}</Link>
                    </li>


                    <div key="nav-item-dropdown-01" className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {data.allMarkdownRemark.nodes[0].frontmatter[`doors_${locale}`]}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li key="nav-item-07" className="nav-item">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/entrance`}
                                    className="nav-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`entrance_doors_${locale}`]}
                                </Link>
                            </li>

                            <div className="dropdown-divider"></div>

                            <li key="nav-item-08" className="nav-item">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/interior`}
                                    className="nav-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`interios_doors_${locale}`]}
                                </Link>
                            </li>

                            <div className="dropdown-divider"></div>

                            <li key="nav-item-09" className="nav-item">
                                <Link
                                    to={`${locale === "he" ? "" : locale}/sliding-doors`}
                                    className="nav-link"
                                >{data.allMarkdownRemark.nodes[0].frontmatter[`sliding_doors_${locale}`]}
                                </Link>
                            </li>
                        </div>
                    </div>
                </ul>

            </div>


            </div>



            </nav>




        </>
    }
  ></StaticQuery>


)

export default Navbar
