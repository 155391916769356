 /* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'

const Locale = ({ currentLocale, pagePath }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "locale_md"}}}) {
          edges {
            node {
              frontmatter {
                list
                en_name
                en_slug
                es_name
                es_slug
                pr_name
                pr_slug
                ru_name
                ru_slug
                fr_name
                fr_slug
                he_name
                he_slug
                img_en_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
                img_he_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
                img_fr_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
                img_pr_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
                img_es_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
                img_ru_flag {
                  childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}



    render={(data, locale) => 
        <>
        
          <div className="container">
            <div className="row">
              <div className="col-12">
                {data.allMarkdownRemark.edges[0].node.frontmatter.list.map(flagLocale => {
                  let goToLocale = flagLocale === "he" ? "" : `${flagLocale}`
                  let goTo = `${goToLocale}${currentLocale === "he" ? pagePath : pagePath.substring(2)}`

                  return (
                    <React.Fragment>
                          <Link
                            key={locale} 
                            to={goTo}
                          >
                            <Img className="locale_flag" fixed={data.allMarkdownRemark.edges[0].node.frontmatter[`img_${flagLocale}_flag`].childImageSharp.fixed} />
                          </Link>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>




        </>
      }
  >
  </StaticQuery>
)

export default Locale
